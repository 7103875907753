import { createContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import uuid from 'react-uuid';

import { DEVICE_FINGERPRINT, generateApiHeader } from '../../../utils/api';

const defaultState = {
  client_id: '',
  client_secret: '',
  scope: '',
  channel_token: '',
  'x-pcf-csrf-token': '',
  responses: {},
};

const CoreContext = createContext(defaultState);

const Core = ({ children }) => {
  const [core, setCore] = useState(defaultState);

  const updateCore = coreValue => {
    setCore(prevCore => {
      return { ...prevCore, ...coreValue };
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const apiHeaderConfig = generateApiHeader();
      if (core.client_id === '') {
        axios
          .get(
            `${process.env.CORE_API_URL}/configuration/client-info/app-type/channel-anonymous-one-app`,
            {
              headers: { ...apiHeaderConfig },
              withCredentials: false,
            },
          )
          .then(configResp => {
            // * Check response status
            if (configResp.status !== 200) {
              console.warn(
                `Looks like there was a problem. Status Code: ${configResp.status}`,
              );
              return;
            }
            const apiHeaderLogin = generateApiHeader();
            axios
              .post(
                `${process.env.CORE_API_URL}/iam/login/anonymous`,
                {
                  deviceFingerprint: DEVICE_FINGERPRINT,
                  authentication: {
                    ...{
                      client_id: configResp.data.clientId,
                      client_secret: configResp.data.clientSecret,
                      scope: configResp.data.scope,
                    },
                    nonce: uuid(),
                  },
                },
                {
                  headers: { ...apiHeaderLogin },
                  withCredentials: false,
                },
              )
              .then(anonyResp => {
                if (anonyResp.status !== 200) {
                  console.warn(
                    `Looks like there was a problem. Status Code: ${anonyResp.status}`,
                  );
                  return;
                }
                updateCore({
                  ...configResp.data,
                  ...anonyResp.data,
                  ...anonyResp.headers.get('x-pcf-csrf-token'),
                });
                // temporary for testing
                window.sessionStorage.setItem('core', core);
              });
          })
          .catch(err => {
            console.warn('Fetch Error: ', err);
          });
      }
    }
  }, []);

  const coreContextValues = useMemo(() => ({ core, updateCore }), []);

  return (
    <CoreContext.Provider value={coreContextValues}>
      {children}
    </CoreContext.Provider>
  );
};

Core.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { Core, CoreContext };
