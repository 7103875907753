import uuid from 'react-uuid';
import merge from 'lodash.merge';

import { timezone } from './helpers';

const BROWSER_SETTINGS = () => {
  if (typeof window !== 'undefined') {
    const language = window.navigator.language.split('-');
    const { userAgent } = window.navigator;
    return {
      localCountry: '',
      localLang: language[0],
      localVariant: language[1] || '',
      userAgent,
    };
  }
  return {
    localCountry: '',
    localLang: '',
    localVariant: '',
    userAgent: '',
  };
};

const FINGERPRINT = (() =>
  Object.keys(BROWSER_SETTINGS())
    .map(key => `${key}#^#${BROWSER_SETTINGS()[key]}`)
    .join('#^#'))();

export const DEVICE_FINGERPRINT = {
  CookieList: {
    CookieData: {
      cookieType: 1,
      fingerPrint: FINGERPRINT,
    },
  },
  SessionData: {
    clientApplication: 'pcf_securesite_web',
  },
  UserData: {
    groupId: 'pcf_securesite_web',
  },
};

export const STATICS = () => {
  // Globals / Request Headers
  if (typeof window !== 'undefined') {
    return {
      accept: 'application/json',
      'channel-timezone-name': timezone,
      fingerprint: FINGERPRINT,
      groupid: 'pcf_securesite_web',
      'uservice-api-version': '1',
      'uservice-channel-type':
        window.innerWidth >= 960 ? 'DESKTOP_WEB' : 'MOBILE_WEB',
      'uservice-correlation-id': uuid().replace(/-/gim, ''),
      'Access-Control-Allow-Credentials': true,
      'Content-Type': 'application/json',
    };
  }
  return {
    accept: 'application/json',
    'channel-timezone-name': timezone,
    fingerprint: FINGERPRINT,
    groupid: 'pcf_securesite_web',
    'uservice-api-version': '1',
    'uservice-channel-type': 'DESKTOP_WEB',
    'uservice-correlation-id': uuid().replace(/-/gim, ''),
    'Access-Control-Allow-Credentials': true,
    'Content-Type': 'application/json',
  };
};

export const GUIDS = () => ({
  'uservice-message-id': uuid().replace(/-/gim, ''),
  'uservice-traceability-id': uuid().replace(/-/gim, ''),
  'x-nonce': uuid(),
});

export const generateApiHeader = () => {
  return merge({}, STATICS(), GUIDS(), {
    language: 'en',
    observe: 'response',
  });
};
