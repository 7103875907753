import { useRef, useEffect } from 'react';
import jstz from 'jstz';

// * Takes a hex value and changes it into an RGB value
export const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace('#', ''),
  );
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const sanitizeURL = url => url.split(' ').join('-').toLowerCase();

export function fluidType(amount, min, max, unit = 'vw', prop = 'font-size') {
  const minBreakpoint = (min / amount) * 100;
  const maxBreakpoint = max ? (max / amount) * 100 : false;
  const dimension = unit === 'vw' ? 'width' : 'height';

  return `
    @media (max-${dimension}: ${minBreakpoint}px) {
      ${prop}: ${min}px;
    }

    ${
      max
        ? `
      @media (min-${dimension}: ${maxBreakpoint}px) {
        ${prop}: ${max}px;
      }
    `
        : ''
    }

    ${prop}: ${amount}${unit}
  `;
}

export const useFocus = () => {
  const elementRef = useRef(null);
  const setElementRef = () => elementRef.current && elementRef.current.focus();
  return [elementRef, setElementRef];
};

export const isNotEmpty = children => {
  return (
    Array.isArray(children) &&
    children.some(child => {
      return (
        (typeof child === 'string' && child.length) || // check if string is not empty
        (typeof child === 'object' && !!child.props
          ? isNotEmpty(child.props.children)
          : false) || // check if returning a react element
        isNotEmpty(child) // check against array
      );
    })
  );
};

export const useInterval = (callback, delay) => {
  const intervalId = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const tick = () => savedCallback.current();

    if (typeof delay === 'number') {
      intervalId.current = window.setInterval(tick, delay);

      return () => window.clearInterval(intervalId.current);
    }
  }, [delay]);

  return intervalId.current;
};

export const timezone = jstz.determine().name();
